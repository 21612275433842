@import '../../scss/mixins';
@import '../../scss/colors';

.UsersManagementPage {
  min-height: 100vh;
  padding-bottom: 72px;

  @include onDesktop {
    padding-bottom: 0;
  }

  .usersListContainer {
    margin-bottom: 20px;

    @include onDesktop {
      margin-bottom: 40px;
    }
  }
}