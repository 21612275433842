@import '../../../../scss/mixins';
@import '../../../../scss/colors';

.RoundButtonWithIcon {
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-accent;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  @include onDesktop {
    height: 60px;
    width: 60px;

    img {
      transform: scale(1.5);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
