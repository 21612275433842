@import '../../../../scss/mixins';

.ProjectManager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 21px;

  @include onDesktop {
    gap: 0;
  }

  .title {
    color: #161616;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;

    @include onDesktop {
      font-size: 12px;
      letter-spacing: 0.32px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    @include onDesktop {
      flex-direction: row;
      gap: 4px;
    }

    .name {
      font-weight: 500;

      @include onDesktop {
        &::after {
          content: "/";
        }
      }
    }

    .text {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      font-size: 8px;
      letter-spacing: 0.16px;
      color: #161616;
      font-style: normal;
      line-height: normal;

      @include onDesktop {
        gap: 8px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.32px;
      }

      .icon {
        width: 22px;
        height: 22px;
        background: #F3F2F2;
      }
    }
  }
}
