@import '../../scss/mixins';

.ForgotPasswordPage {
  min-height: 100vh;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onDesktop {
      width: 570px;
      margin-top: 64px;
      align-self: flex-start;
    }

    .title {
      margin: 0 0 36px;
      color: #161616;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.8px;

      @include onDesktop {
        margin-bottom: 45px;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 1.05px;
      }
    }

    .description {
      color: #606161;
      line-height: 24px;
    }

    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include onDesktop {
        gap: 20px;
      }

      .inputContainer {
        display: flex;
        flex-direction: column;
      }
    }
  }
}