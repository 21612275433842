@import '../../scss/mixins';
@import '../../scss/colors';

.dialog {
  max-width: 325px;
  max-height: 152px;
  width: 100%;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 100;

  @include onDesktop {
    max-width: 600px;
    max-height: 206px;
    right: calc(50% - 300px / 2);
  }
}

.ChangeUserModal {
  padding: 20px 16px;
  border: 1px solid $color-primary;
  border-radius: 25px;
  background: #F8F8F8;

  @include onDesktop {
    padding: 26px 20px;
    border-radius: 45px;
  }

  .closeButton {
    padding: 0;
    position: absolute;
    top: 24px;
    right: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .title {
    margin: 0 0 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.52px;
    color: $color-primary;

    @include onDesktop {
      font-size: 20px;
    }
  }
}
