@import "../../../../../../scss/mixins";
@import "../../../../../../scss/colors";

.Responsibilities {
  display: none;
  flex-direction: column;
  justify-content: center;

  @include onDesktop {
    width: 100%;
    display: flex;
  }

  &.bottom {
    width: 100%;
    display: flex;

    .content {
      max-width: unset;
      white-space: normal;
      overflow: unset;
      color: #161616;
      font-size: 6px;
      font-weight: 300;

      @include onDesktop {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .title {
    color: #161616;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.18px;

    @include onDesktop {
      font-size: 12px;
      letter-spacing: 0.32px;
    }
  }

  .content {
    max-width: 100%;
    color: #161616;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include onDesktop {
      font-size: 14px;
      letter-spacing: 0.32px;
    }
  }

  .textareaContainer {
    position: relative;
    background-color: $color-accent;
    border-radius: 40px;

    @include onDesktop {
      padding-top: 26px;
    }

    &::after {
      content: "Responsibilities";
      position: absolute;
      top: 8px;
      left: 20px;
      color: #161616;
      font-size: 6px;
      font-weight: 300;
      letter-spacing: 0.32px;

      @include onDesktop {
        font-size: 13px;
      }
    }
  }

  .textarea {
    font-size: 10px;

    @include onDesktop {
      font-size: 14px;
    }
  }
}