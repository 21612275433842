@import '../../scss/mixins';

.LoginPage {
  min-height: 100vh;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onDesktop {
      width: 440px;
      margin-top: -160px;
      align-self: center;
    }

    .title {
      color: #161616;
      font-size: 27px;
      font-weight: 500;
      letter-spacing: 0.81px;

      @include onDesktop {
        font-size: 34px;
        letter-spacing: 1.35px;
      }
    }

    .inputs {
      width: 100%;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include onDesktop {
        margin-bottom: 24px;
      }

      .inputContainer {
        display: flex;
        flex-direction: column;
      }

      .checkboxContainer {
        display: flex;
        align-items: center;
        padding: 0 15px;
        gap: 10px;
      }

      .passwordContainer {
        display: flex;
        flex-direction: column;
        position: relative;

        .passwordVisibilityToggle {
          padding: 0;
          display: flex;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          border: none;
          background: transparent;
          cursor: pointer;

          @include onDesktop {
            right: 36px;
          }

          &.slashed {
            &::after {
              content: '';
              height: 1px;
              width: 40px;
              display: block;
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translate(50%, -50%) rotate(35deg);
              background: #606161;
            }
          }
        }
      }
    }

    .forgotPassword {
      align-self: flex-end;
      margin-bottom: 28px;
      color: #4F4E4E;
      font-size: 15px;
      font-weight: 600;

      @include onDesktop {
        font-size: 16px;
      }
    }
  }
}