@import '../../scss/mixins';
@import '../../scss/colors';

.ContactUsPage {
  min-height: 100vh;

  .backContainer {
    display: none;

    @include onDesktop {
      height: 90px;
      display: block;
    }
  }

  .content {
    @include onDesktop {
      min-height: calc(100vh - 120px - 32px);
      width: 100%;
      display: flex;
      gap: 40px;
      align-items: center;
      justify-content: space-between;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .right {
    img {
      display: none;

      @include onDesktop {
        max-width: 460px;
        width: 100%;
        display: block;
      }
    }
  }

  .title {
    margin: 0 0 20px;
    color: $color-primary;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    @include onDesktop {
      display: none;
    }
  }

  .formContainer {
    margin-bottom: 28px;
    padding: 15px;
    border-radius: 25px;
    background: $color-accent;

    @include onDesktop {
      margin-bottom: 20px;
      padding: 0;
      background: unset;
    }

    &.submitted {
      background-color: unset;
    }

    .personImage {
      width: 185px;
      display: flex;
      margin: -28px auto 20px;

      @include onDesktop {
        display: none;
      }
    }

    .textWrapper {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      color: #161616;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.2px;

      @include onDesktop {
        gap: 20px;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.8px;
      }

      .hint {
        margin-bottom: 15px;
        padding: 12px 45px 12px 15px;
        position: relative;
        border-radius: 80px;
        border: 1px solid $color-primary;

        @include onDesktop {
          margin-bottom: 30px;
          padding: 14px 64px 14px 18px;
        }

        &::after {
          content: "";
          height: 22px;
          width: 22px;
          display: block;
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          background-color: $color-primary;
          background-image: url('../../assets/icons/arrow-down-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;

          @include onDesktop {
            height: 40px;
            width: 40px;
            right: 14px;
            background-size: 14px;
          }
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      @include onDesktop {
        gap: 20px;
      }

      .input,
      .textarea {
        font-size: 13px;
        letter-spacing: 0.26px;
      }

      .input {
        background: $color-background;
        font-weight: 500;

        @include onDesktop {
          background: $color-accent;
          font-size: 16px;
          letter-spacing: 0.5px;
        }
      }

      .textareaContainer {
        height: 180px;
      }

      .textarea {
        font-weight: 300;

        @include onDesktop {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .sendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    img {
      width: 16px;

      @include onDesktop {
        width: 30px;
      }
    }
  }

  .submit {
    @include onDesktop {
      max-width: 500px;
      width: 100%;
    }

    .homeIcon {
      margin: 0;
      height: 24px;
      width: 24px;

      @include onDesktop {
        height: 80px;
        width: 80px;
      }
    }

    .title {
      margin: 0;
      display: flex;
      gap: 14px;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $color-primary;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.64px;

      @include onDesktop {
        font-size: 26px;
        line-height: 28px;
        letter-spacing: 1.4px;
      }

      .homeIcon {
        display: block;

        @include onDesktop {
          display: none;
        }
      }
    }

    .homeIcon {
      display: none;

      @include onDesktop {
        margin: 60px auto;
        display: flex;
      }
    }

    .text {
      display: none;

      @include onDesktop {
        margin: 0;
        display: block;
        color: $color-primary;
        font-size: 16px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: 0.88px;
      }
    }

    .backButton {
      width: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 18px;
      position: absolute;
      right: 20px;
      left: 20px;
      bottom: 40px;

      @include onDesktop {
        display: none;
      }

      img {
        width: 16px;
      }
    }
  }
}