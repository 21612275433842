@import '../../scss/mixins';
@import '../../scss/colors';

.dialog {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.EditorModal {
  height: 100%;
  background: #F8F8F8;

  .content {
    height: 100%;
    position: relative;

    .saveButton {
      width: 360px;
      position: absolute;
      bottom: 40px;
      right: 50%;
      transform: translateX(50%);
      font-size: 24px;
      cursor: pointer;
    }
  }
}
