@import '../../scss/mixins';
@import '../../scss/colors';

.CreateEngagementPage {
  min-height: 100vh;

  .createEngagementForm {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    background: $color-accent;

    @include onDesktop {
      background: transparent;
    }

    .title {
      margin: 0 0 14px 0;
      color: $color-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.32px;

      @include onDesktop {
        text-align: center;
        margin-bottom: 30px;
        font-size: 34px;
        font-weight: 600;
        letter-spacing: 1.8px;
      }
    }

    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .input {
        border-radius: 20px;
        background: $color-background;

        @include onDesktop {
          border-radius: 40px;
          background: $color-accent;
        }
      }

      .inputContainer {
        display: flex;
        flex-direction: column;

        .input {
          height: 40px;
          color: #161616;
          font-size: 13px;
          font-weight: 300;
          letter-spacing: 0.26px;

          @include onDesktop {
            height: 60px;
            padding: 12px 30px;
            font-size: 22px;
            font-weight: 400;
            letter-spacing: 0.6px;
          }
        }
      }

      .buttons {
        @include onDesktop {
          display: flex;
          gap: 18px;
        }

        .dropdownContainer {
          @include onDesktop {
            width: 50%;
          }
        }

        .createButton {
          width: unset;
          position: fixed;
          right: 20px;
          bottom: 40px;
          left: 20px;
          color: #F6F6F6;
          font-size: 15px;
          font-weight: 500;

          @include onDesktop {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            position: relative;
            right: unset;
            bottom: unset;
            left: unset;
            font-size: 18px;
          }
        }
      }
    }
  }
}
