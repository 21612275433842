@import '../../../../scss/mixins';
@import '../../../../scss/colors';

.BillingHistoryItem {
  @include onDesktop {
    display: flex;
    gap: 12px;
  }

  .paymentLog {
    height: 40px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 55px;
    background: $color-accent;
  
    @include onDesktop {
      height: 60px;
      padding: 0 30px;
      display: flex;
      flex-grow: 1;
    }

    .title {
      color: #161616;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.2px;
  
      @include onDesktop {
        font-size: 16px;
      }
    }
  
    .amount {
      font-size: 9px;
      letter-spacing: 0.18px;
      
      @include onDesktop {
        font-size: 16px;
      }
    }
  }


  .openEngagementButton {
    display: none;

    @include onDesktop {
      min-width: 60px;
      display: flex;
      background: $color-accent;

      img {
        transform: rotate(90deg) scale(1.5);
      }
    }
  }
}