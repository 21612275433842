@import '../../scss/mixins';
@import '../../scss/colors';

.PaymentsPage {
  min-height: 100vh;

  .title {
    margin: 0 0 20px;
    color: $color-primary;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    @include onDesktop {
      display: none;
    }
  }

  .innerContainer {
    display: flex;
    flex-direction: column;

    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
      gap: 80px;
    }

    .noBillingHistory {
      margin: 0 0 32px;
      color: #161616;
      font-size: 15px;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0.3px;

      @include onDesktop {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }

    .billingHistoryContainer {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      order: 1;

      @include onDesktop {
        margin-bottom: 40px;
        flex-grow: 1;
        order: 0;
      }
    }

    .cardContainer {
      margin: 0 auto 20px;
      display: flex;

      @include onDesktop {
        width: 395px;
        margin: 0;
      }
    }
  }
}