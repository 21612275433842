@import '../../../../scss/mixins';
@import '../../../../scss/colors';

.Search {
  width: 100%;

  @include onDesktop {
    background: $color-accent;
  }

  .searchInput {
    width: 100%;
    padding: 0 0 10px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    outline: none;
    font-size: 13px;
    letter-spacing: 0.36px;
    color: $color-primary;

    @include onDesktop {
      font-size: 14px;
      background: $color-accent;
    }
  }
}