@import '../../../scss/mixins';
@import '../../../scss/colors';

.Switch {
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 35px;
  background: $color-accent;

  @include onDesktop {
    width: max-content;
    border-radius: 28px;
  }

  .option {
    height: 40px;
    width: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 35px;
    border: none;
    background: transparent;
    cursor: pointer;

    @include onDesktop {
      height: 60px;
      padding-inline: 20px;
      width: max-content;
      min-width: 150px;
      font-size: 15px;
      border-radius: 28px;
    }

    &.selected {
      background: #262A41;
      color: $color-background;
      font-weight: 500;
    }
  }
}