.ServiceProviderProfilePage {
  .infoContainer {
    margin-bottom: 8px;
    padding: 10px 22px 20px;
    background: #D9D9D9;

    .title {
      margin: 0 0 14px;
      font-size: 14px;
      font-weight: 700;
      line-height: 40px;
    }

    .info {
      margin: 0;
      display: flex;
      gap: 40px;
      align-items: center;

      .hourlyRateTitle {
        color: #4F4E4E;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.4px;
      }

      .hourlyRateValue {
        color: #2F2E2E;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.4px;
      }

      .hourlyRateButton {
        padding: 0;
        border: none;
        background: transparent;
        color: #3A7AB6;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.4px;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .billingInfo {
      display: flex;
      gap: 8px;
      align-items: center;

      .text {
        color: #606161;
        font-size: 11px;
        line-height: 14px;
      }
    }
  }

  .privateProfileLink {
    display: block;
    width: 100%;
    color: #606161;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    text-decoration: none;
    cursor: pointer;
  }
}