@import '../../../../scss/mixins';
@import '../../../../scss/colors';

.LogHours {
  width: 100%;
  display: flex;

  @include onDesktop {
    width: unset;
  }

  .logHoursButton {
    height: 40px;
    padding: 0;
    font-size: 10px;
    text-align: center;

    @include onDesktop {
      height: 60px;
      min-width: 180px;
      max-width: 180px;
      padding-left: 22px;
      font-size: 14px;
      text-align: left;
    }
  }

  .inputContainer {
    height: 40px;
    width: 100%;
    position: relative;
    background: #f3f3f3;
    border-radius: 55px;

    &::after {
      content: ':';
      position: absolute;
      left: 159px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 55px;
      color: #4f4e4e;

      @include onDesktop {
        left: 60px;
      }
    }

    @include onDesktop {
      height: 60px;
      padding-right: 60px;
    }

    .input {
      padding: 0;
      height: 100%;
      width: 50%;
      background: #f3f3f3;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .submitButton {
      height: 100%;
      width: 40px;
      position: absolute;
      top: 0;
      right: 0;
      background: $color-primary;

      @include onDesktop {
        width: 60px;
      }
    }
  }
}