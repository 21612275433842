@import '../../../scss/mixins';
@import '../../../scss/colors';

.EngagementsItem {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 25px;
  background: $color-accent;

  @include onDesktop {
    padding: 30px;
  }

  &.serviceProvider {
    .middle {
      display: flex;
      flex-direction: column;

      .customerContainer {
        gap: 20px;
      }

      .ratesContainer {
        justify-content: space-between;
        gap: 60px;

        @include onDesktop {
          order: 2;
        }
      }
    }
  }

  .row {
    position: relative;
    display: flex;
    align-items: center;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;

    @include onDesktop {
      gap: 12px;
    }
  }

  .colTitle {
    color: #161616;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;

    @include onDesktop {
      font-size: 16px;
      letter-spacing: 0.5px;
    }
  }

  .text {
    margin: 0;
    color: #606161;
    font-weight: 400;
    line-height: 11px;

    @include onDesktop {
      line-height: 24px;
    }
  }

  .top {
    justify-content: space-between;

    @include onDesktop {
      height: 60px;
    }

    .buttons {
      gap: 12px;

      @include onDesktop {
        gap: 18px;
      }

      .actionButton {
        background: $color-primary;

        &.active {
          background: #9496A1;
        }
      }
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .customerContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      justify-content: space-between;
      order: 2;

      .addHiresContainer {
        width: 100%;
        position: relative;

        @include onDesktop {
          width: unset;
        }
      }

      @include onDesktop {
        flex-direction: row;
        gap: 0;
      }
    }
  }

  .billingContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 26px;

    @include onDesktop {
      flex-direction: row;

      .logHours {
        order: 1;
      }
    }

    .billingRateInfo {
      display: flex;
      align-items: center;
      gap: 12px;

      .infoIcon {
        display: none;

        @include onDesktop {
          display: inline-flex;
        }
      }

      .text {
        color: #606161;
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}