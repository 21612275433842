@import '../../../scss/mixins';
@import '../../../scss/colors';

.PasswordInputs {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .inputContainer {
    position: relative;

    .input {
      width: 100%;
      background: $color-background;

      @include onDesktop {
        background: $color-accent;
      }
    }
  }

  .changePassword {
    display: none;
    color: #4F4E4E;
    font-size: 15px;
    font-weight: 600;
    text-decoration-line: underline;
    text-align: center;
    cursor: pointer;

    @include onDesktop {
      margin-bottom: 40px;
      display: block;
      font-size: 16px;
      text-align: right;
    }
  }
}
