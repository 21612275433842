@import '../../../../../scss/mixins';
@import '../../../../../scss/colors';

.HiresItem {
  display: flex;

  @include onDesktop {
    gap: 20px;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 55px;
    background: $color-background;

    .top {
      display: flex;
      padding-right: 40px;

      @include onDesktop {
        padding-right: 76px;
      }
    }

    .info {
      width: 100%;
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));

      @include onDesktop {
        gap: 12px;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      }

      .title {
        color: #161616;
        font-size: 9px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.18px;

        @include onDesktop {
          font-size: 12px;
          letter-spacing: 0.32px;
        }
      }

      .content {
        color: #161616;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;

        @include onDesktop {
          font-size: 12px;
          letter-spacing: 0.32px;
        }
      }

      .responsibilitiesContainer {
        display: none;
      }

      .statusContainer {
        display: flex;
      }

      .input,
      .textarea {
        width: 100%;
        background: $color-accent;
        font-size: 8px;

        @include onDesktop {
          font-size: 14px;
        }
      }
    }

    .bottom {
      display: none;
    }
  }

  .actionButtons {
    display: none;

    @include onDesktop {
      display: flex;
      gap: 12px;
    }

    .editButton {
      background: $color-primary;

      @include onDesktop {
        background: #F3F2F2;
      }
    }

    .deleteButton {
      background: transparent;
      border: 1px solid $color-primary;

      @include onDesktop {
        border: none;
        background: #F3F2F2;
      }
    }

    .saveButton {
      background: $color-primary;
    }
  }

  .expandButton {
    position: absolute;
    right: 0;
    background: $color-primary;

    @include onDesktop {
      background: transparent;

      &.open {
        display: block;
        background: transparent;
        transform: rotate(180deg);
      }
    }
  }

  &.open {
    .top {
      .responsibilitiesContainer {
        @include onDesktop {
          display: none;
        }
      }
    }
  }

  &.expanded {
    .infoContainer {
      gap: 10px;
      border-radius: 20px;

      @include onDesktop {
        border-radius: 35px;
      }

      .top {
        .responsibilitiesContainer {
          @include onDesktop {
            display: none;
          }
        }
      }

      .bottom {
        padding: 0 8px 8px;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: flex-start;

        @include onDesktop {
          padding: 0 0 30px 70px;
        }

        .ratesContainer {
          width: 100%;

          .ratesInnerContainer {
            margin-bottom: 12px;
            display: flex;
            flex-direction: row;
            gap: 22px;

            @include onDesktop {
              display: none;
            }
          }
        }

        .statusAndButtonsContainer {
          display: flex;
          gap: 20px;
        }

        .statusContainer {
          display: none;
        }

        .actionButtons {
          display: flex;
          gap: 6px;
          justify-content: flex-end;

          @include onDesktop {
            display: none;
          }
        }
      }
    }

    .expandButton {
      background: transparent;

      @include onDesktop {
        transform: none;
      }
    }
  }

  &.editing {
    .infoContainer {
      .info {
        display: flex;

        .name {
          display: none;
        }
      }

      .bottom {
        .statusAndButtonsContainer {
          flex-direction: column;

          .statusContainer {
            order: 2;
          }
        }
      }
    }
  }
}