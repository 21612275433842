@import '../../../scss/mixins';
@import '../../../scss/colors';

.Card {
  width: 288px;
  height: 158px;

  @include onDesktop {
    position: relative;
    height: 216px;
  }

  .innerCard {
    width: 100%;
    display: flex;
    border-radius: 35px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjg4IiBoZWlnaHQ9IjE1OCIgdmlld0JveD0iMCAwIDI4OCAxNTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMjg3IiBoZWlnaHQ9IjE1NyIgcng9IjM0LjUiIHN0cm9rZT0iIzRGNEU0RSIgc3Ryb2tlLWRhc2hhcnJheT0iMTUgMTUiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;

    @include onDesktop {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzk1IiBoZWlnaHQ9IjIxNyIgdmlld0JveD0iMCAwIDQwNCAyMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjAuMzcwMDk5IiB5PSIxLjI0MzQ1IiB3aWR0aD0iMzk1IiBoZWlnaHQ9IjIxNC40NyIgcng9IjU5LjgyNTkiIHN0cm9rZT0iIzRGNEU0RSIgc3Ryb2tlLXdpZHRoPSIwLjc1MjUyNyIgc3Ryb2tlLWRhc2hhcnJheT0iMTYuNTYgMTYuNTYiLz4KPC9zdmc+Cg==");
    }

    &.filled {
      background: linear-gradient(108deg, #262A41 8.32%, rgba(159, 161, 172, 0.77) 22.38%, rgba(92, 95, 112, 0.54) 61.47%, #262A41 94.63%, rgba(217, 217, 217, 0.00) 124.01%);
    }

    .empty {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      gap: 12px;
      cursor: pointer;

      img {
        width: 32px;
        height: 32px;
      }

      span {
        color: #4F4E4E;
        font-size: 15px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
    }

    .filling {
      width: 100%;
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 36px;

      @include onDesktop {
        justify-content: center;
        gap: 60px;
      }

      .cardNumberElement {
        width: 50%;

        @include onDesktop {
          width: 150px;
        }
      }

      .cvcAndExpiresElement {
        width: 60px;
      }

      .top {
        width: 100%;
        display: flex;
        justify-content: center;

        @include onDesktop {
          img {
            transform: scale(1.5);
          }
        }
      }

      .bottom {
        width: 100%;
        padding: 0 24px;
        display: flex;
        justify-content: space-between;

        @include onDesktop {
          padding: 0 60px;
        }
      }
    }

    .filled {
      width: 100%;
      display: flex;
      gap: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .currentBalance {
        color: #FFF;
        letter-spacing: 0.56px;
      }

      .balance {
        color: #FFF;
        font-size: 26px;
        letter-spacing: 0.52px;
      }

      .cardNumber {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 12px;
        color: #FFF;
        font-size: 15px;
        letter-spacing: 0.525px;

        @include onDesktop {
          img {
            padding-left: 12px;
            transform: scale(1.25);
          }
        }

        .dotsGroup {
          display: flex;
          gap: 3px;

          .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background: #f6f6f6;
          }
        }
      }
    }

    .loading {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }
  }

  .createButton {
    width: unset;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 40px;

    @include onDesktop {
      width: 170px;
      padding-left: 20px;
      position: absolute;
      left: 50%;
      right: 50%;
      bottom: -90px;
      transform: translateX(-50%);
      font-size: 14px;
      text-align: left;
    }
  }
}