@import '../../../../scss/mixins';

.BackButton {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 6px;

  @include onDesktop {
    margin-bottom: 30px;
    gap: 18px;
  }

  .label {
    color: rgba(22, 22, 22, 0.70);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.4px;

    @include onDesktop {
      font-size: 18px;
      letter-spacing: 1px;
    }
  }
}
