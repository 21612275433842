@import '../../../scss/mixins';

.Avatar {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  margin-right: 12px;
  border-radius: 50%;
  object-fit: cover;

  @include onDesktop {
    height: 60px;
    min-width: 60px;
    max-width: 60px;
    margin-right: 14px;
  }
}