@import '../../scss/mixins';
@import '../../scss/colors';

.Pagination {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 6px;
  list-style: none;

  @include onDesktop {
    gap: 12px;
  }

  .previous,
  .page,
  .next {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-accent;
    border-radius: 50%;
    cursor: pointer;

    @include onDesktop {
      height: 45px;
      width: 45px;
    }
  }

  .page {
    .pageLink {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.28px;
      user-select: none;

      @include onDesktop {
        font-size: 26px;
        letter-spacing: 0.7px;
      }
    }
  }

  .previous,
  .next {
    position: relative;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &::after {
      content: '';
      height: 100%;
      width: 100%;
      display: block;
      position: absolute;
      background-image: url('../../assets/icons/arrow-expand.svg');
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;

      @include onDesktop {
        transform: scale(1.5);
      }
    }
  }

  .previous {
    &::after {
      transform: rotate(180deg);

      @include onDesktop {
        transform: rotate(180deg) scale(1.5);
      }
    }
  }

  .previousLink,
  .nextLink {
    height: 100%;
    width: 100%;
  }

  .activePage {
    background: $color-primary;

    .activePageLink {
      color: #F6F6F6;
    }
  }
}
