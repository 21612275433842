@import '../../../../../scss/mixins';
@import '../../../../../scss/colors';

.HiresOption {
  width: 100%;
  padding-right: 15px;
  display: flex;
  border-radius: 20px;
  background: rgba(234, 233, 233, 0.36);
  cursor: pointer;

  @include onDesktop {
    padding-right: 30px;
    border-radius: 35px;
    background: rgba(234, 233, 233, 0.36);
  }

  &.selected {
    height: unset;
    padding: 0 45px 12px 0;
    background: $color-background;
    cursor: unset;

    @include onDesktop {
      width: 800px;
      padding-right: 80px;
      background: rgba(246, 246, 246, 0.72);
    }

    .infoContainer {
      gap: 10px;
      justify-content: unset;

      .info {
        gap: 4px;

        @include onDesktop {
          gap: 20px;
        }

        &:last-child {
          gap: 0;
        }
      }
    }

    .inputContainer {
      width: 100%;
      position: relative;

      .input {
        height: 40px;
        width: 100%;
        padding: 8px;
        font-size: 8px;
        background: $color-accent;

        @include onDesktop {
          height: 60px;
          padding: 16px 60px 16px 26px;
          font-size: 12px;
        }
      }

      &::after {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: #161616;
        font-size: 9px;
        font-weight: 300;
        letter-spacing: 0.26px;

        @include onDesktop {
          content: "/ week";
          right: 30px;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.4px;
        }
      }

      &.rate {
        &::after {
          content: '$';
          font-size: 12px;

          @include onDesktop {
            content: '$ / hour';
          }
        }
      }
    }

    .textareaContainer {
      height: 200px;
      width: 100%;
      background: $color-accent;

      @include onDesktop {
        height: 400px;
      }
    }

    .textarea {
      font-size: 9px;

      @include onDesktop {
        font-size: 12px;
      }
    }
  }

  .infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        display: none;
        text-align: left;
        color: #161616;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.2px;

        @include onDesktop {
          display: block;
          font-size: 12px;
          letter-spacing: 0.32px;
        }
      }

      .email {
        display: flex;
        align-items: center;
        color: rgba(22, 22, 22, 0.70);
        font-size: 9px;
        letter-spacing: 0.18px;

        @include onDesktop {
          font-size: 12px;
          letter-spacing: 0.32px;
        }
      }
    }
  }
}