@import '../../scss/mixins';
@import '../../scss/colors';

.Toolbar {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @include onDesktop {
    margin-bottom: 30px;
  }

  .title {
    margin: 0;
    color: $color-primary;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    @include onDesktop {
      display: none;
    }
  }

  .actions {
    display: flex;

    @include onDesktop {
      width: 100%;
      justify-content: space-between;
    }

    .buttons {
      display: flex;
      gap: 6px;
    }

    .createButton {
      width: unset;
      position: fixed;
      left: 20px;
      right: 20px;
      bottom: 40px;
      color: #F6F6F6;
      font-size: 15px;
      font-weight: 500;
      z-index: 4;

      @include onDesktop {
        min-width: 170px;
        padding: 0 60px 0 16px;
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.36px;
      }
    }
  }
}