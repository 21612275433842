@import '../../scss/mixins';
@import '../../scss/colors';

.ReferralsPage {
  min-height: 100vh;

  .title {
    margin: 0 0 20px;
    color: $color-primary;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    @include onDesktop {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 16px;
    border-radius: 25px;
    background-color: $color-accent;

    @include onDesktop {
      padding: 30px;
    }

    p {
      margin: 0;
      color: rgba(22, 22, 22, 0.70);
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0.2px;

      @include onDesktop {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.44px;
      }
    }
  }
}