@import '../../scss/mixins';
@import '../../scss/colors';

.HeaderMenuContainer {
  height: 100%;

  @include onDesktop {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;

    .container {
      flex-grow: 1;
    }
  }

  .content {
    padding: 0 20px 25px;

    @include onDesktop {
      padding: 0 40px 32px;
    }
  }
}
