@import '../../scss/mixins';

.LoginContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  @include onDesktop {
    padding-bottom: 0;
  }

  &.admin {
    .content {
      @include onDesktop {
        justify-content: center;
      }

      .personImage {
        @include onDesktop {
          display: none;
        }
      }
    }
  }

  .header {
    padding: 20px 32px 36px;
    display: flex;
    justify-content: center;

    @include onDesktop {
      justify-content: flex-start;
      padding: 40px 72px;
    }
  }

  .content {
    padding: 0 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onDesktop {
      padding: 0 136px 0;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
    }

    .personImage {
      height: 305px;
      width: 247px;

      @include onDesktop {
        display: flex;
        margin-bottom: auto;
        height: calc(100vh - 130px - 50px);
        width: unset;
        order: 2;
        object-fit: contain;
      }
    }
  }
}