@import '../../scss/mixins.scss';

.EngagementsPage {
  min-height: 100vh;
  padding-bottom: 72px;

  @include onDesktop {
    padding-bottom: 0;
  }

  .engagementsListContainer {
    margin-bottom: 40px;
  }
}
