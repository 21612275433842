@import '../../../scss/mixins';

.InfoIcon {
  display: inline-flex;

  img {
    height: 10px;
    width: 10px;

    @include onDesktop {
      height: 20px;
      width: 20px;
    }
  }
}
