@import '../../scss/mixins';

.UsersList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;

  @include onDesktop {
    gap: 20px;
  }
}
