@import './scss/colors'; 

* {
  box-sizing: border-box;
  font-family: inherit;
  scrollbar-color: rgba(148, 150, 161, 0.5) transparent;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: rgba(148, 150, 161, 0.5);
    border-radius: 10px;
  }
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background: $color-background;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
