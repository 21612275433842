@import '../../../../scss/mixins';

.HiresList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;

  @include onDesktop {
    gap: 12px;
  }

  .title {
    color: #161616;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;

    @include onDesktop {
      font-size: 18px;
      letter-spacing: 0.5px;
    }
  }

  .noHires {
    color: #161616;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.3px;

    @include onDesktop {
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
}