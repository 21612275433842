@import '../../scss/mixins';
@import '../../scss/colors';

.Menu {
  @include onDesktop {
    min-width: 300px;
    max-width: 300px;
    width: 100%;

    &::after {
      content: "";
      position: fixed;
      min-height: 100vh;
      top: 0;
      left: 0;
      right: calc(var(--right) + 80px);
      background: $color-primary;
    }
  }

  .menuContainer {
    min-height: 100vh;
    width: 210px;
    padding: 36px 28px;
    position: fixed;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
    background: $color-primary;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    z-index: 10;
    flex-shrink: 0;

    @include onDesktop {
      width: 300px;
      padding: 40px 0 0 44px;
      transform: translateX(0);
      border-bottom-right-radius: unset;
    }

    .logo {
      @include onDesktop {
        height: 54px;
        width: 180px;
        margin: 0 auto;
        display: flex;
      }
    }
  
    .list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      list-style: none;
  
      @include onDesktop {
        gap: 0;
        background: $color-background;
  
        &::after {
          content: '';
          position: absolute;
          top: 120px;
          left: 10px;
          width: 72px;
          height: calc(100vh - 120px);
          background: $color-primary;
        }
      }
  
      .item {
        padding: 12px 16px 12px 28px;
        display: flex;
        position: relative;
        background: $color-primary;
  
        @include onDesktop {
          height: 60px;
          padding: 18px 24px 18px 60px;
          z-index: 1;

          &:first-of-type {
            height: 0;
          }
        }
  
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
  
          .label {
            color: #f6f6f6;
            font-size: 12px;
            font-weight: 400;
  
            @include onDesktop {
              font-size: 15px;
              overflow: hidden;
            }
          }
        }
  
        &.prev {
          border-bottom-right-radius: 60px;
        }
  
        &.after {
          border-top-right-radius: 60px;
        }
  
        &.active {
          border-radius: 60px;
          background: $color-background;
  
          @include onDesktop {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
  
          .label {
            color: $color-primary;
          }
        }
  
        .icon {
          display: flex;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translate(-50%, -50%);
  
          @include onDesktop {
            left: 40px;
            top: 35px;
            transform: scale(1.5) translate(-50%, -50%);
          }
        }
      }
    }
  }

  &.open {
    .menuContainer {
      transform: translate(0);
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(38, 42, 65, 0.30);
  backdrop-filter: blur(1px);
  z-index: 9;

  @include onDesktop {
    display: none;
  }
}