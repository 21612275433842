@import '../../../scss/mixins';
@import '../../../scss/colors';

.Input {
  height: 45px;
  padding: 14px 20px;
  border: none;
  border-radius: 55px;
  background: $color-accent;
  color: #4f4e4e;
  font-size: 15px;
  font-weight: 400;
  outline: none;

  @include onDesktop {
    height: 60px;
    padding: 16px 26px;
    font-size: 16px;
  }

  &.error {
    background: rgba(219, 40, 40, 0.14) !important;
  }
}
