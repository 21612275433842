@import '../../../scss/mixins';

.EngagementsList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;

  @include onDesktop {
    gap: 30px;
  }
}
