@import '../../scss/mixins.scss';

.gettingStartedPage {
  min-height: 100vh;
  padding-bottom: 72px;

  @include onDesktop {
    padding-bottom: 0;
  }
}
