@import '../../scss/mixins';
@import '../../scss/colors';

.CreateOrEditUserForm {
  .form {
    margin-bottom: 35px;
    padding: 20px 15px 15px;
    border-radius: 25px;
    background: $color-accent;

    @include onDesktop {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: transparent;
    }

    .title {
      margin: 0 0 20px;
      color: $color-primary;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.32px;

      @include onDesktop {
        margin: 0 0 30px;
        text-align: center;
        font-size: 34px;
        font-weight: 600;
        letter-spacing: 1.8px;
      }
    }

    .formContainer {
      @include onDesktop {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        gap: 30px;
      }

      .avatarAndRolesContainer {
        margin-bottom: 38px;
        display: flex;
        align-items: center;
        gap: 30px;

        @include onDesktop {
          margin: 0;
          flex-direction: column;
          align-items: center;
          gap: 42px;
        }
      }

      .inputs {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        @include onDesktop {
          max-width: 560px;
          margin-bottom: 0;
          flex-grow: 1;
          gap: 12px;
        }
      }
    }

    .inputContainer {
      width: 100%;
      display: flex;
      flex-direction: column;

      .input {
        border-radius: 20px;
        background: $color-background;
        color: #161616;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.26px;

        @include onDesktop {
          border-radius: 40px;
          background: $color-accent;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.4px;
        }

        &.rate {
          padding-right: 100px;
        }
      }

      .rateContainer {
        display: flex;
        flex-direction: column;
        position: relative;

        &::after {
          content: '$ / hour';
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: #161616;
          font-size: 13px;
          font-weight: 300;
          letter-spacing: 0.26px;

          @include onDesktop {
            right: 30px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .buttonsContainer {
      display: flex;
      gap: 10px;

      .editorButton,
      .publicProfileButton {
        height: 45px;
        margin: 0;
        padding: 14px 20px;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 20px;
        background: $color-background;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.26px;
        cursor: pointer;
        
        @include onDesktop {
          height: 70px;
          padding: 20px 36px;
          border-radius: 40px;
          background: $color-accent;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.4px;
        }
      }

      .editorButton {
        width: 100%;
        color: rgba(219, 40, 40, 0.63);
      }

      .publicProfileButton {
        justify-content: center;

        @include onDesktop {
          width: 300px;
        }
      }
    }
  }

  .deleteButton {
    margin: 0 auto 80px;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    font-size: 15px;
    font-weight: 600;
    text-decoration-line: underline;
    color: $color-primary;
    background: transparent;

    @include onDesktop {
      display: none;
    }

    img {
      height: 26px;
      width: 26px;
    }
  }

  .createButton {
    width: unset;
    position: fixed;
    left: 20px;
    right: 20px;
    bottom: 40px;

    @include onDesktop {
      max-width: 570px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: unset;
      right: unset;
      bottom: unset;
    }
  }
}