@import '../../../../scss/mixins';
@import '../../../../scss/colors';

.Description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  order: 1;
  color: #606161;
  font-size: 8px;

  @include onDesktop {
    width: 100%;
    font-size: 16px;
    flex-grow: 1;
    gap: 12px;
  }

  &.open {
    .text {
      min-height: 130px;
      height: unset;
      display: inline;
    }
  }

  .title {
    color: #161616;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;

    @include onDesktop {
      font-size: 18px;
      letter-spacing: 0.5px;
    }
  }

  .text {
    margin: 0;
    line-height: 12px;
    color: #606161;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;

    @include onDesktop {
      font-size: 14px;
      line-height: 24px;
    }

    &.more {
      -webkit-line-clamp: unset;
    }
  }

  .readMore {
    color: #00A1D4;
    line-height: 24px;
    cursor: pointer;
    font-size: 12px;
  }

  .textareaContainer {
    height: 152px;
    position: relative;
    border-radius: 35px;
    background: #F6F6F6;

    @include onDesktop {
      height: 400px;
    }

    .descriptionButton {
      background: $color-primary;
      position: absolute;
      right: 16px;
      bottom: 11px;
    }
  }
}