@import '../../../scss/mixins';

.RoleCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include onDesktop {
    max-width: 294px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
  }
}