@import '../../../scss/mixins';
@import '../../../scss/colors';

.ServiceProviderDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include onDesktop {
    margin-bottom: 20px;
  }

  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .input {
      border-radius: 20px;
      background: $color-background;
      color: #161616;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.26px;

      @include onDesktop {
        border-radius: 40px;
        background: $color-accent;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.4px;
      }

      &.rate {
        padding-right: 100px;
      }
    }

    .rateContainer {
      display: flex;
      flex-direction: column;
      position: relative;

      &::after {
        content: '$ / hour';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: #161616;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.26px;

        @include onDesktop {
          right: 30px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .publicProfileButton {
      height: 45px;
      margin: 0;
      padding: 14px 20px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      border: none;
      border-radius: 20px;
      color: rgba(219, 40, 40, 0.63);
      background: $color-background;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.26px;
      cursor: pointer;

      @include onDesktop {
        height: 60px;
        padding: 20px 36px;
        border-radius: 40px;
        background: $color-accent;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.4px;
      }

      &:disabled {
        cursor: default;
      }
    }

    .editorButton {
      background-color: $color-primary;
    }
  }
}